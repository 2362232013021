<template>
  <content-block :class="$style.content">
    <template #content>
      <h2 :class="$style.title">{{ $t('views.error.title') }}</h2>
      <div :class="$style.errors" v-if="!!views.errors">
        <span v-for="(error, i) in views.errors" :key="i">
          {{ $ttr(`errors.${error}`, $ttr(error)) }}
        </span>
      </div>
    </template>
  </content-block>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { ContentBlock } from '@/components/page';
import { useBodyClass, useDarkTheme } from '@/components/composables';

import { useViewsStore } from '../../store';

export default defineComponent({
  name: 'Error',
  components: {
    ContentBlock,
  },
  setup() {
    useBodyClass('error');
    useDarkTheme();

    const views = useViewsStore();

    return {
      views,
    };
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.content {
  width: 100%;
  margin: auto auto auto 0em;

  @include mixins.media(xs, max) {
    padding: 0 4vw;
  }

  @include mixins.media(sm) {
    padding: 0;
    width: 90vw;
    margin-left: 1.25em;
  }

  @include mixins.media(md) {
    width: 70vw;
    margin-left: 2.5em;
  }

  @include mixins.media(lg) {
    width: 35vw;
    margin-left: 0;
    min-height: 400px;
    min-width: initial;
  }

  @include mixins.media(md, max) {
    display: flex;
    flex: 1;

    > div {
      margin: auto 0;
    }
  }
}

.title {
  margin-bottom: 1em;
}

.errors {
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 2.5em;
  border: 1px solid vars.$light-gray;
  border-radius: 6px;

  span {
    @include mixins.relative-font-size(15px, 0.625vw);
    line-height: 2;
    font-weight: 400;
    color: vars.$yellow;

    @include mixins.media(xxl) {
      line-height: 1;
    }
  }
}
</style>
