import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_back_icon = _resolveComponent("arrow-back-icon")!
  const _component_primary_button = _resolveComponent("primary-button")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(_ctx.$style['desktop-header'])
  }, [
    (_ctx.hasBackButton)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: _normalizeClass({
        [_ctx.$style['section-left']]: true,
        [_ctx.$style.hidden]: _ctx.hideArrow,
      }),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('back')))
        }, [
          _createVNode(_component_arrow_back_icon)
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    (_ctx.hasCancelButton)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.$style['section-right'])
        }, [
          _createVNode(_component_primary_button, {
            rounded: "",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$ttr('buttons.cancel')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}