import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([
      _ctx.$style.button,
      _ctx.$style[_ctx.variant],
      _ctx.disabled ? _ctx.$style.disabled : '',
      _ctx.medium ? _ctx.$style.medium : '',
      _ctx.large ? _ctx.$style.large : '',
      _ctx.rounded ? _ctx.$style.rounded : '',
    ]),
    disabled: _ctx.disabled
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}