<template>
  <svg :class="$style.icon" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.0001 9C17.5524 9 18.0001 8.55228 18.0001 8C18.0001 7.44772 17.5524 7 17.0001 7L17.0001 9ZM0.293014 7.29289C-0.0975094 7.68342 -0.0975093 8.31658 0.293015 8.70711L6.65698 15.0711C7.0475 15.4616 7.68067 15.4616 8.07119 15.0711C8.46171 14.6805 8.46171 14.0474 8.07119 13.6569L2.41434 8L8.07119 2.34315C8.46171 1.95262 8.46171 1.31946 8.07119 0.928933C7.68067 0.538409 7.0475 0.538409 6.65698 0.928933L0.293014 7.29289ZM17.0001 7L1.00012 7L1.00012 9L17.0001 9L17.0001 7Z"
      fill="currentColor"
    />
  </svg>
</template>

<style lang="scss" module>
@use '~styles/variables' as vars;

.icon {
  width: 18px;
  height: auto;

  color: vars.$light-gray;
}
</style>
