<template>
  <main-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { default as MainView } from './components/MainView.vue';
import { useViewsStore } from './store';

export default defineComponent({
  components: {
    MainView,
  },
  setup: () => {
    const views = useViewsStore();

    return {
      views,
    };
  },
  mounted() {
    const { errors, step } = window.BACKEND_DATA;

    this.views.setErrors(errors);
    this.views.setStep(step);
  },
});
</script>
