<template>
  <a :class="$style.link">
    <span v-if="iconComponent" :class="$style['icon-left']">
      <component :is="iconComponent" />
    </span>

    <span :class="$style.label"><slot /></span>

    <arrow-up-right-icon v-if="external" :class="$style['icon-right']" />
  </a>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { ArrowUpRightIcon, ChatIcon, DocIcon } from '@/components/base';

export default defineComponent({
  components: {
    ArrowUpRightIcon,
    ChatIcon,
    DocIcon,
  },
  props: {
    icon: {
      type: String as PropType<'chat' | 'doc' | null>,
      default: null,
    },
    external: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconComponent() {
      switch (this.icon) {
        case 'chat':
          return 'chat-icon';
        case 'doc':
          return 'doc-icon';
        default:
          return null;
      }
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.link {
  width: 100%;
  height: 44px;
  font-weight: 400;

  display: grid;
  grid-template-columns: 20px 1fr 12px;
  grid-template-areas: 'icon-left label icon-right';
  gap: 10px;
  align-content: center;

  padding: 10px 10px 10px 20px;

  &,
  &:active,
  &:hover,
  &:visited {
    color: vars.$dark-gray;
    text-decoration: none;
  }

  :global(.dark-theme) & {
    &,
    &:active,
    &:hover,
    &:visited {
      color: vars.$light-gray;
    }
  }

  &:hover {
    background-color: rgba(31, 41, 55, 0.04);

    border-radius: 6px;

    :global(.dark-theme) & {
      background-color: rgba(31, 41, 55, 0.2);
    }
  }
}

.label {
  grid-area: label;
  align-self: center;

  color: inherit;
  font-size: 15px;
  line-height: 1;
  font-weight: 400;
}

.icon {
  @include mixins.center-flex();
  align-self: center;
  justify-self: center;

  &,
  svg {
    color: inherit;
  }
}

.icon-left {
  @extend .icon;
  grid-area: icon-left;
}

.icon-right {
  @extend .icon;
  grid-area: icon-right;
  width: 12px;
  opacity: 0.8;
}
</style>
