// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".la{width:84px;height:auto;color:#1f2937}", "",{"version":3,"sources":["webpack://./src/components/base/atoms/DriversIcon.vue","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAGA,IACE,UAAA,CACA,WAAA,CAEA,aCNU","sourcesContent":["\n@use '~styles/variables' as vars;\n\n.icon {\n  width: 84px;\n  height: auto;\n\n  color: vars.$dark-gray;\n}\n","// Colors\n$dark-gray: #1f2937;\n$light-gray: #f2f2f2;\n$red: #ff7373;\n$yellow: #d2fd3c;\n\n// Breakpoints\n$breakpoints: (\n  // max sm - 1\n  xs: 0,\n  // min : max md - 1\n  sm: 480px,\n  // min : max lg -1\n  md: 768px,\n  // min : max xl - 1\n  lg: 992px,\n  // min : max xxl - 1\n  xl: 1200px,\n  // min\n  xxl: 1920px\n);\n\n$z-index-backdrop: 1;\n$z-index-backdrop-above: $z-index-backdrop + 1;\n$z-index-toasts: $z-index-backdrop-above + 1;\n$z-index-overlay: $z-index-toasts + 1;\n$z-index-loader: $z-index-overlay + 1;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "la"
};
module.exports = ___CSS_LOADER_EXPORT___;
