import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_icon = _resolveComponent("info-icon")!
  const _component_content_block = _resolveComponent("content-block")!

  return (_openBlock(), _createBlock(_component_content_block, null, {
    content: _withCtx(() => [
      _createElementVNode("h1", {
        class: _normalizeClass(_ctx.$style.title)
      }, _toDisplayString(_ctx.$t('views.success.title')), 3 /* TEXT, CLASS */),
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.$style.description)
      }, _toDisplayString(_ctx.$t('views.success.description')), 3 /* TEXT, CLASS */),
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.$style.info)
      }, [
        _createVNode(_component_info_icon),
        _createTextVNode(),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.success.info')), 1 /* TEXT */)
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }))
}