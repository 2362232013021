import { createPinia } from 'pinia';
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';

import App from './App.vue';
import { createI18nHelper, options } from './locales';

import './styles/index.scss';

const i18n = createI18n(options);
const i18nHelper = createI18nHelper(i18n);
const pinia = createPinia();

const app = createApp(App);
app.use(i18n);
app.use(i18nHelper);
app.use(pinia);

app.mount('#app-container');
