import { locales } from '@/locales/locales';

import { messages } from './messages';

export const options = {
  locale: locales.de.code,
  fallbackLocale: locales.de.code,
  messages: {
    [locales.de.code]: messages,
  },
  warnHtmlInMessage: 'off',
};
