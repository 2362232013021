import { renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    appear: "",
    "enter-active-class": 
      _ctx.variant === 'a'
        ? _ctx.$style['slide-enter-active-a']
        : _ctx.variant === 'b'
          ? _ctx.$style['slide-enter-active-b']
          : _ctx.variant === 'c'
            ? _ctx.$style['slide-enter-active-c']
            : _ctx.$style['slide-enter-active-d']
    ,
    "enter-from-class": 
      _ctx.variant === 'a'
        ? _ctx.$style['slide-enter-from-a']
        : _ctx.variant === 'b'
          ? _ctx.$style['slide-enter-from-b']
          : _ctx.variant === 'c'
            ? _ctx.$style['slide-enter-from-c']
            : _ctx.$style['slide-enter-from-d']
    ,
    "enter-to-class": _ctx.$style['slide-enter-to']
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["enter-active-class", "enter-from-class", "enter-to-class"]))
}