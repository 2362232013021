<template>
  <svg :class="$style.icon" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 0C6.01664 0 4.56659 0.439867 3.33323 1.26398C2.09986 2.08809 1.13856 3.25943 0.570907 4.62987C0.0032496 6.00032 -0.145275 7.50832 0.144114 8.96317C0.433503 10.418 1.14781 11.7544 2.1967 12.8033C3.2456 13.8522 4.58197 14.5665 6.03683 14.8559C7.49168 15.1453 8.99968 14.9967 10.3701 14.4291C11.7406 13.8614 12.9119 12.9001 13.736 11.6668C14.5601 10.4334 15 8.98336 15 7.5C14.9977 5.51158 14.2068 3.60525 12.8008 2.19923C11.3947 0.793201 9.48842 0.00228935 7.5 0ZM7.5 0.714285C8.6959 0.714285 9.76402 2.17834 10.3166 4.28571H4.68336C5.23599 2.17834 6.3041 0.714285 7.5 0.714285ZM10.4769 5C10.6356 5.82385 10.7151 6.661 10.7143 7.5C10.7151 8.33899 10.6356 9.17615 10.4769 10H4.5231C4.20659 8.34836 4.20659 6.65164 4.5231 5H10.4769ZM5.50677 1.0141C4.72779 1.96171 4.19086 3.08449 3.94222 4.28571H1.52605C2.37392 2.71645 3.80299 1.54194 5.50677 1.0141ZM0.714289 7.5C0.715084 6.64353 0.879083 5.79507 1.19751 5H3.79155C3.49806 6.65374 3.49806 8.34625 3.79155 10H1.19751C0.879083 9.20493 0.715084 8.35646 0.714289 7.5ZM1.52605 10.7143H3.94222C4.19086 11.9155 4.72779 13.0383 5.50677 13.9859C3.80299 13.4581 2.37392 12.2835 1.52605 10.7143ZM7.5 14.2857C6.3041 14.2857 5.23599 12.8217 4.68336 10.7143H10.3166C9.76402 12.8217 8.6959 14.2857 7.5 14.2857ZM9.49324 13.9859C10.2722 13.0383 10.8091 11.9155 11.0578 10.7143H13.474C12.6261 12.2835 11.197 13.4581 9.49324 13.9859ZM14.2857 7.5C14.2849 8.35646 14.1209 9.20493 13.8025 10H11.2085C11.3558 9.17484 11.4295 8.33821 11.4286 7.5C11.4295 6.66179 11.3558 5.82516 11.2085 5H13.8025C14.1209 5.79507 14.2849 6.64353 14.2857 7.5ZM11.0578 4.28571C10.8091 3.08449 10.2722 1.96171 9.49324 1.0141C11.197 1.54194 12.6261 2.71645 13.474 4.28571H11.0578Z"
      fill="currentColor"
    />
  </svg>
</template>

<style lang="scss" module>
@use '~styles/variables' as vars;

.icon {
  width: 15px;
  height: auto;

  color: vars.$dark-gray;
}
</style>
