<template>
  <button
    :class="[
      $style.button,
      $style[variant],
      disabled ? $style.disabled : '',
      medium ? $style.medium : '',
      large ? $style.large : '',
      rounded ? $style.rounded : '',
    ]"
    :disabled="disabled"
  >
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String as PropType<'normal' | 'inverted'>,
      default: 'normal',
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 3em;
  min-width: 7em;
  padding: 9px 15px;

  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  text-align: center;

  &.disabled {
    opacity: 0.45;
    cursor: initial;
  }

  &.medium {
    padding: 9px 1.5em;
    height: 60px;

    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    letter-spacing: -0.03em;
  }

  &.large {
    height: 3.6em;
    min-width: initial;
    padding: 9px 36px;

    font-size: 1.5em;
    letter-spacing: -0.02em;
    line-height: 22px;

    @include mixins.media(sm) {
      height: 3.5em;
    }

    @include mixins.media(md) {
      font-size: 24px;
      height: 3.3em;
    }

    @include mixins.media(xxl) {
      height: 2.8em;
      font-size: 1.4vw;
    }

    &:disabled {
      opacity: 1;
      filter: brightness(35%) saturate(55%);
    }
  }

  &.rounded {
    border-radius: 6px;
  }

  &.normal {
    &,
    &:hover,
    &:active {
      color: vars.$dark-gray;
      background: rgba(21, 23, 26, 0.12);
    }
  }

  &.inverted {
    &,
    &:hover,
    &:active {
      color: vars.$dark-gray;
      background: vars.$yellow;
    }

    &:hover {
      background: vars.$yellow;
    }
  }

  &.normal.large,
  &.normal.medium {
    &,
    &:hover,
    &:active {
      color: vars.$yellow;
      background: vars.$dark-gray;
    }

    &:hover {
      background: rgba(vars.$dark-gray, 0.95);
    }
  }

  &.inverted.large,
  &.inverted.medium {
    &,
    &:hover,
    &:active {
      color: vars.$dark-gray;
      background: vars.$yellow;
    }

    &:hover {
      background: vars.$yellow;
    }
  }
}
</style>
