import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tippy = _resolveComponent("tippy")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_primary_button = _resolveComponent("primary-button")!
  const _component_custom_form = _resolveComponent("custom-form")!
  const _component_content_block = _resolveComponent("content-block")!

  return (_openBlock(), _createBlock(_component_content_block, null, {
    header: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.header),
        innerHTML: _ctx.$t('views.enterVin.description')
      }, null, 10 /* CLASS, PROPS */, _hoisted_1)
    ]),
    content: _withCtx(() => [
      _createElementVNode("h1", {
        class: _normalizeClass(_ctx.$style.title)
      }, _toDisplayString(_ctx.$t('views.enterVin.title')), 3 /* TEXT, CLASS */),
      _createVNode(_component_custom_form, {
        "error-messages": _ctx.errorMessages,
        "set-errors": _ctx.setErrors,
        onSubmit: _ctx.completeStep
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.form)
          }, [
            _createVNode(_component_text_input, {
              minlength: "17",
              maxlength: "17",
              name: "vin",
              type: "text",
              required: "",
              error: _ctx.vinError,
              placeholder: _ctx.$t('views.enterVin.input'),
              modelValue: _ctx.vin,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vin) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_tippy, {
                  placement: "top",
                  arrow: false,
                  hideOnClick: false
                }, {
                  content: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('views.enterVin.tooltip')), 1 /* TEXT */)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      class: _normalizeClass(_ctx.$style.tooltip)
                    }, _toDisplayString(_ctx.$t('views.enterVin.tooltipTrigger')), 3 /* TEXT, CLASS */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["error", "placeholder", "modelValue"])
          ], 2 /* CLASS */),
          _createVNode(_component_primary_button, {
            "data-role": "submit",
            class: _normalizeClass(_ctx.$style.cta),
            type: "submit",
            large: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('buttons.continue')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["error-messages", "set-errors", "onSubmit"])
    ]),
    _: 1 /* STABLE */
  }))
}