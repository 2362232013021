import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_up_right_icon = _resolveComponent("arrow-up-right-icon")!

  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass(_ctx.$style.link)
  }, [
    (_ctx.iconComponent)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(_ctx.$style['icon-left'])
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.iconComponent)))
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.$style.label)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2 /* CLASS */),
    (_ctx.external)
      ? (_openBlock(), _createBlock(_component_arrow_up_right_icon, {
          key: 1,
          class: _normalizeClass(_ctx.$style['icon-right'])
        }, null, 8 /* PROPS */, ["class"]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}