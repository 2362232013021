// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".T span{text-decoration:underline;font-weight:500}.U{margin-bottom:3rem}.V{margin-bottom:3.5rem}.W{padding:0 1em;font-size:12px;line-height:20px;color:#1f2937;text-decoration:underline;cursor:pointer}.X{width:100%}", "",{"version":3,"sources":["webpack://./src/vw-dat/components/views/EnterVIN.vue","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAGA,QACE,yBAAA,CACA,eAAA,CAGF,GACE,kBAAA,CAGF,GACE,oBAAA,CAGF,GACE,aAAA,CAEA,cAAA,CACA,gBAAA,CACA,aCpBU,CDqBV,yBAAA,CACA,cAAA,CAGF,GACE,UAAA","sourcesContent":["\n@use '~styles/variables' as vars;\n\n.header span {\n  text-decoration: underline;\n  font-weight: 500;\n}\n\n.title {\n  margin-bottom: 3rem;\n}\n\n.form {\n  margin-bottom: 3.5rem;\n}\n\n.tooltip {\n  padding: 0 1em;\n\n  font-size: 12px;\n  line-height: 20px;\n  color: vars.$dark-gray;\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n.cta {\n  width: 100%;\n}\n","// Colors\n$dark-gray: #1f2937;\n$light-gray: #f2f2f2;\n$red: #ff7373;\n$yellow: #d2fd3c;\n\n// Breakpoints\n$breakpoints: (\n  // max sm - 1\n  xs: 0,\n  // min : max md - 1\n  sm: 480px,\n  // min : max lg -1\n  md: 768px,\n  // min : max xl - 1\n  lg: 992px,\n  // min : max xxl - 1\n  xl: 1200px,\n  // min\n  xxl: 1920px\n);\n\n$z-index-backdrop: 1;\n$z-index-backdrop-above: $z-index-backdrop + 1;\n$z-index-toasts: $z-index-backdrop-above + 1;\n$z-index-overlay: $z-index-toasts + 1;\n$z-index-loader: $z-index-overlay + 1;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "T",
	"title": "U",
	"form": "V",
	"tooltip": "W",
	"cta": "X"
};
module.exports = ___CSS_LOADER_EXPORT___;
