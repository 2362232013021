<template>
  <content-block>
    <template #content>
      <h1 :class="$style.title">{{ $t('views.success.title') }}</h1>

      <p :class="$style.description">{{ $t('views.success.description') }}</p>

      <p :class="$style.info">
        <info-icon /> <span>{{ $t('views.success.info') }}</span>
      </p>
    </template>
  </content-block>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { ContentBlock } from '@/components/page';
import { InfoIcon } from '@/components/base';

export default defineComponent({
  name: 'Success',
  components: {
    ContentBlock,
    InfoIcon,
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;

.title {
  margin-bottom: 24px;
}

.description {
  max-width: 100ch;

  @include mixins.relative-font-size(15px, 0.825vw);
  font-weight: 400;
  line-height: 1.5;
}

.info {
  display: flex;
  gap: 12px;
  align-items: center;
  background-color: rgba(21, 23, 26, 0.12);
  border-top: 1px #b2d637;
  border-radius: 6px;
  margin-top: 40px;
  padding: 10px;

  > span {
    font-size: 13px;
  }
}
</style>
