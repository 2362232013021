<template>
  <div :class="$style['locale-selector']" ref="containerRef">
    <button :class="$style['locale-btn']" @click="toggleDropdown">
      <globe-icon :class="$style['icon-globe']" /> <span>{{ activeLocale }}</span>
      <arrow-up-right-icon :class="$style['icon-arrow']" />
    </button>

    <nav :class="$style.dropdown" v-if="dropdownVisible">
      <a v-for="{ label, code } in inactiveLocales" :key="code" @click="setLocale(code)">
        {{ label }}
      </a>
    </nav>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';

import { ArrowUpRightIcon, GlobeIcon } from '@/components/base';
import { Locale, localeCodeToLabel, locales } from '@/locales';

export default defineComponent({
  components: {
    ArrowUpRightIcon,
    GlobeIcon,
  },
  setup() {
    const containerRef = ref(null);
    const dropdownVisible = ref(false);

    onClickOutside(containerRef, () => {
      dropdownVisible.value = false;
    });

    return { containerRef, dropdownVisible };
  },
  computed: {
    activeLocale() {
      return localeCodeToLabel(this.$i18n.locale);
    },
    inactiveLocales() {
      return Object.values(locales).filter(({ code }) => code !== this.$i18n.locale);
    },
  },
  methods: {
    setLocale(locale: Locale) {
      this.$setLocale(locale);
      this.toggleDropdown();
    },
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/variables' as vars;

.locale-selector {
  position: relative;
  display: flex;
}

.locale-btn {
  display: flex;
  align-items: center;
  height: 42px;
  padding: 0 15px 0 10px;

  border: 1px solid vars.$dark-gray;
  border-radius: 6px;

  :global(.dark-theme) & {
    border-color: vars.$light-gray;
  }

  span {
    color: #222222;
    font-size: 13px;
    line-height: 1;
    margin: 0 20px 0 10px;

    :global(.dark-theme) & {
      color: vars.$light-gray;
    }
  }

  .icon-globe {
    width: 18px;
    color: inherit;
  }

  .icon-arrow {
    width: 14px;
    color: inherit;
  }
}

.dropdown {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  transform: translateY(calc(-100% - 3px));

  display: flex;
  background-color: vars.$dark-gray;
  border-radius: 6px;
  color: vars.$yellow;

  a,
  a:active,
  a:hover,
  a:visited {
    font-size: 14px;
    line-height: 20px;
    color: vars.$yellow;

    padding: 14px 20px;
  }
}
</style>
