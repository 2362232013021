import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_icon = _resolveComponent("info-icon")!
  const _component_tippy = _resolveComponent("tippy")!

  return (_openBlock(), _createBlock(_component_tippy, {
    placement: "top-end",
    arrow: false,
    popperOptions: _ctx.popperOptions,
    hideOnClick: false,
    offset: [0, 4]
  }, _createSlots({
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.tag, _ctx.hasTooltip ? _ctx.$style['with-tooltip'] : ''])
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.$style.label)
        }, _toDisplayString(_ctx.capability), 3 /* TEXT, CLASS */),
        (_ctx.hasTooltip)
          ? (_openBlock(), _createBlock(_component_info_icon, {
              key: 0,
              class: _normalizeClass(_ctx.$style.icon)
            }, null, 8 /* PROPS */, ["class"]))
          : _createCommentVNode("v-if", true)
      ], 2 /* CLASS */)
    ]),
    _: 2 /* DYNAMIC */
  }, [
    (_ctx.hasTooltip)
      ? {
          name: "content",
          fn: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.permissions)
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.permissions, (permission) => {
                return (_openBlock(), _createElementBlock("span", { key: permission }, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.capability) + ": ", 1 /* TEXT */),
                  _createElementVNode("span", null, _toDisplayString(permission), 1 /* TEXT */)
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ], 2 /* CLASS */)
          ]),
          key: "0"
        }
      : undefined
  ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["popperOptions"]))
}