import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_content_block = _resolveComponent("content-block")!

  return (_openBlock(), _createBlock(_component_content_block, {
    class: _normalizeClass(_ctx.$style.content)
  }, {
    content: _withCtx(() => [
      _createElementVNode("h2", {
        class: _normalizeClass(_ctx.$style.title)
      }, _toDisplayString(_ctx.$t('views.error.title')), 3 /* TEXT, CLASS */),
      (!!_ctx.views.errors)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.errors)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.views.errors, (error, i) => {
              return (_openBlock(), _createElementBlock("span", { key: i }, _toDisplayString(_ctx.$ttr(`errors.${error}`, _ctx.$ttr(error))), 1 /* TEXT */))
            }), 128 /* KEYED_FRAGMENT */))
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class"]))
}