<template>
  <svg :class="$style.icon" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.5 11.25C22.5 17.4632 17.4632 22.5 11.25 22.5C5.0368 22.5 0 17.4632 0 11.25C0 5.0368 5.0368 0 11.25 0C17.4632 0 22.5 5.0368 22.5 11.25Z"
      fill="white"
      id="circle"
    />
    <path
      d="M12.2546 12.7815C12.2375 13.3213 11.79 13.75 11.25 13.75V13.75C10.71 13.75 10.2625 13.3213 10.2454 12.7815L10.0325 6.03152C10.0147 5.46717 10.4674 5 11.032 5H11.468C12.0326 5 12.4853 5.46717 12.4675 6.03152L12.2546 12.7815Z"
      fill="currentColor"
    />
    <rect
      x="12.5"
      y="17.5"
      width="2.5"
      height="2.5"
      rx="1.25"
      transform="rotate(-180 12.5 17.5)"
      fill="currentColor"
    />
  </svg>
</template>

<style lang="scss" module>
@use '~styles/variables' as vars;

.icon {
  width: 23px;
  height: auto;

  color: #15171a;

  :global(#circle) {
    fill: vars.$yellow;
  }
}
</style>
