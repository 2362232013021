// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".da{width:198px;height:auto}", "",{"version":3,"sources":["webpack://./src/components/base/atoms/PrivacyDivisionIcon.vue"],"names":[],"mappings":"AAGA,IACE,WAAA,CACA,WAAA","sourcesContent":["\n@use '~styles/variables' as vars;\n\n.icon {\n  width: 198px;\n  height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "da"
};
module.exports = ___CSS_LOADER_EXPORT___;
