<template>
  <svg :class="$style.icon" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.646447 7.64645C0.451184 7.84171 0.451184 8.15829 0.646447 8.35355C0.841709 8.54882 1.15829 8.54882 1.35355 8.35355L0.646447 7.64645ZM8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z"
      fill="currentColor"
    />
  </svg>
</template>

<style lang="scss" module>
@use '~styles/variables' as vars;

.icon {
  width: 9px;
  height: auto;

  color: vars.$dark-gray;
}
</style>
