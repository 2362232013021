<template>
  <div :class="$style.sidebar">
    <slide-fade>
      <div :class="$style.container">
        <div :class="$style['driver-icon']">
          <drivers-icon />
        </div>

        <div :class="$style.content">
          <slot />
        </div>

        <div :class="$style.navigation">
          <p :class="$style.version">drivers.app version 0.1</p>

          <div :class="$style.links">
            <link-with-icon
              icon="chat"
              href="https://www.drivers.app/feedback"
              target="_blank"
              external
            >
              {{ $ttr('sidebar.feedback') }}
            </link-with-icon>
            <link-with-icon
              icon="doc"
              href="https://www.high-mobility.com/legal/end-customer-car-data-terms"
              target="_blank"
              external
            >
              {{ $ttr('sidebar.terms') }}
            </link-with-icon>
            <link-with-icon
              icon="doc"
              href="https://www.high-mobility.com/legal/end-customer-car-data-privacy"
              target="_blank"
              external
            >
              {{ $ttr('sidebar.privacy') }}
            </link-with-icon>
          </div>

          <div>
            <a
              :class="$style.privacy"
              href="https://www.high-mobility.com/company/security"
              target="_blank"
            >
              <privacy-division-icon />
            </a>
          </div>
        </div>
      </div>
    </slide-fade>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { DriversIcon, LinkWithIcon, PrivacyDivisionIcon, SlideFade } from '@/components/base';

export default defineComponent({
  components: {
    DriversIcon,
    LinkWithIcon,
    PrivacyDivisionIcon,
    SlideFade,
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: var(--sidebar-width);

  @include mixins.media(md, max) {
    display: none;
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 12px;
  border-right: 1px solid rgba(21, 23, 26, 0.12);

  :global(.dark-theme) & {
    border-right-color: hsla(0, 0%, 94.9%, 0.12);
  }
}

.driver-icon {
  @include mixins.center-flex();
  height: 80px;
  flex-shrink: 0;

  > svg {
    max-width: 6em;
    width: auto;

    color: inherit;

    :global(.dark-theme) & {
      height: 1.6em;
    }

    @include mixins.media(xxl) {
      :global(.dark-theme) & {
        height: 2.2em;
        max-width: 100%;
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 24px 0;
}

.navigation {
  margin-top: auto;
}

.version {
  padding-bottom: 8px;
  margin-bottom: 24px;

  color: #838383;
  font-size: 11px;

  border-bottom: 1px solid rgba(21, 23, 26, 0.12);

  :global(.dark-theme) & {
    display: none;
  }
}

.links {
  display: flex;
  flex-direction: column;

  a {
    margin-bottom: 4px;
  }

  a span {
    @include mixins.relative-font-size(15px, 0.625vw);
    line-height: 2;
  }

  @include mixins.media(xxl) {
    a {
      height: 4em;
      grid-template-columns: 24px 1fr 14px;
    }

    a > span:first-child > svg {
      width: 24px;
    }

    a > svg {
      width: 14px;
    }
  }
}

.privacy {
  display: flex;

  > svg {
    margin: 42px 0 14px 21px;

    filter: invert(100%);
    mix-blend-mode: darken;
    opacity: 0.65;
  }

  :global(.dark-theme) & {
    > svg {
      filter: initial;
      mix-blend-mode: normal;
      opacity: 0.65;
    }
  }

  &:hover > svg {
    opacity: 0.9;
  }
}
</style>
