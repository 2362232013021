<template>
  <main :class="$style['simple-layout']">
    <slot />
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style lang="scss" module>
@use '~styles/functions' as fn;
@use '~styles/mixins' as mixins;

.simple-layout {
  display: flex;
  align-items: center;
  width: 100%;

  min-height: fn.vh(100);

  @include mixins.media(md, max) {
    flex-direction: column;
  }

  @include mixins.media(lg) {
    margin-left: max(25%, calc(var(--sidebar-width) + 2.4em));
  }

  @media (min-width: fn.get-breakpoint-width(lg)) and (max-width: 1400px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
