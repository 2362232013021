import { defineStore } from 'pinia';

import { Step } from '../types';

export interface ViewsState {
  errors: string[];
  history: Step[];
  step: Step;
  vin: string;
}

export const useViewsStore = defineStore('views', {
  state: (): ViewsState => {
    return {
      errors: [],
      history: [],
      step: Step.EnterVin,
      vin: '',
    };
  },
  actions: {
    /**
     * Handle errors
     */
    setErrors(errors: string[]) {
      this.errors = errors;
    },

    /**
     * Form data
     */
    setVin(vin: string) {
      this.vin = vin;
    },

    /**
     * Navigation logic
     */
    setStep(step: Step) {
      this.history.push(this.step);
      this.step = step;
    },
    restorePreviousStep() {
      if (this.history.length) {
        this.step = this.history.pop() as Step;
      }
    },
  },
  getters: {
    /**
     * Return error step, if there are errors, otherwise current value
     */
    activeStep: ({ errors, step }) => (errors.length > 0 ? Step.Error : step),
  },
});
