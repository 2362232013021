<template>
  <div :class="$style.container">
    <div v-if="hasHeader" :class="$style.header">
      <div :class="$style['header-inner']">
        <slot name="header" />
      </div>
    </div>
    <div :class="$style.content">
      <slot name="content" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  setup(_, { slots }) {
    const hasHeader = ref(false);
    if (slots.header && slots.header().length) {
      hasHeader.value = true;
    }

    return {
      hasHeader,
    };
  },
});
</script>

<style lang="scss" module>
@use '~styles/functions' as fn;
@use '~styles/mixins' as mixins;

.container {
  width: auto;

  @include mixins.media(lg) {
    padding: 42px;
    border-radius: 12px;
    background-color: rgba(183, 220, 52, 0.4);

    :global(.dark-theme) & {
      padding: 0;
      background: transparent;
    }
  }

  @include mixins.media(lg) {
    min-width: 50%;
  }

  @include mixins.media(xl) {
    max-width: min(calc(75% + 2 * 42px), 100%);
  }
}

.header {
  padding: 3.5em 1.25em 2.5em;
  border-bottom: 1px solid rgba(21, 23, 26, 0.12);

  @include mixins.media(md) {
    padding: 3.5em 2.5em 2.5em;
  }

  @include mixins.media(lg) {
    padding: 2.5em;
    margin-bottom: 2.5em;

    border: 1px solid rgba(21, 23, 26, 0.12);
    border-radius: 6px;
  }

  :global(.dark-theme) & {
    padding: 0;
  }

  .header-inner {
    max-width: 100ch;

    @include mixins.relative-font-size(15px, 0.825vw);
    font-weight: 400;
    line-height: 1.5;
  }
}

.content {
  padding: 2.5em 1.25em 2.5em;
  width: 100%;

  @include mixins.media(md) {
    padding: 2.5em 2.5em 2.5em;
  }

  @include mixins.media(lg) {
    padding: 0;
  }

  :global(.dark-theme) & {
    padding: 0;
  }
}
</style>
