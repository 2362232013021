<template>
  <header :class="$style['desktop-header']">
    <a
      v-if="hasBackButton"
      :class="{
        [$style['section-left']]: true,
        [$style.hidden]: hideArrow,
      }"
      @click="$emit('back')"
    >
      <arrow-back-icon />
    </a>
    <div v-if="hasCancelButton" :class="$style['section-right']">
      <primary-button rounded @click="$emit('cancel')">{{ $ttr('buttons.cancel') }}</primary-button>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useWindowScroll } from '@vueuse/core';

import { ArrowBackIcon, PrimaryButton } from '@/components/base';

export default defineComponent({
  emits: ['back', 'cancel'],
  components: {
    ArrowBackIcon,
    PrimaryButton,
  },
  setup() {
    const { y: scrollTop } = useWindowScroll();

    return {
      scrollTop,
    };
  },
  props: {
    hasBackButton: {
      type: Boolean,
      default: true,
    },
    hasCancelButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hideArrow() {
      return this.scrollTop > 0;
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/functions' as fn;
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.desktop-header {
  position: fixed;
  top: 0px;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: min(75%, calc(100% - var(--sidebar-width) - 2.4em));
  gap: 10px;
  padding: 2em 2.5em 2em 0;

  :global(.dark-theme) &,
  :global(.visuals-background) & {
    background: transparent;
  }

  @include mixins.media(md, max) {
    display: none;
  }

  @media (min-width: fn.get-breakpoint-width(lg)) and (max-width: 1400px) {
    position: relative;
    inset: initial;
    width: 100%;
    padding-right: 0;
  }
}

.section {
  display: flex;
  align-items: center;
}

.section-left {
  @extend .section;

  padding: 10px 8px;
  margin-left: -8px;

  text-decoration: none;
  font-size: 14px;
  line-height: 20px;

  transition:
    transform 0.35s ease-in-out,
    opacity 0.2s ease-in-out;

  &.hidden {
    transform: translateY(-100%);
    opacity: 0;
  }

  svg {
    color: vars.$dark-gray;

    :global(.dark-theme) & {
      color: vars.$light-gray;
    }
  }
}

.section-right {
  @extend .section;

  margin-left: auto;
}
</style>
