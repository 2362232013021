<template>
  <header
    :class="{
      [$style['mobile-header']]: true,
      [$style.scroll]: scroll,
    }"
  >
    <div
      :class="{
        [$style['header-wrapper']]: true,
        [$style.scroll]: scroll,
        [$style['with-back-btn']]: hasBackButton,
      }"
    >
      <a v-if="hasBackButton" :class="$style['section-left']" @click="$emit('back')">
        <arrow-back-icon />
      </a>

      <drivers-icon :class="$style.logo" />

      <div :class="$style['section-right']" v-if="!alwaysOpen">
        <primary-button :class="$style['close-btn']" rounded v-if="showMenu" @click="toggleMenu">
          <close-icon-rounded />
        </primary-button>
        <primary-button :class="$style['open-btn']" rounded v-else @click="toggleMenu">
          {{ $t('buttons.menu') }}
        </primary-button>
      </div>
    </div>

    <div :class="$style['menu-wrapper']" v-if="showMenu" data-role="menu">
      <div :class="$style['content-wrapper']" v-if="$slots.default">
        <slot />
      </div>

      <div :class="$style.links">
        <link-with-icon
          icon="doc"
          href="https://www.high-mobility.com/legal/end-customer-car-data-terms"
          target="_blank"
          external
          :class="$style.link"
        >
          {{ $t('sidebar.terms') }}
        </link-with-icon>
        <link-with-icon
          icon="doc"
          href="https://www.high-mobility.com/legal/end-customer-car-data-privacy"
          target="_blank"
          external
          :class="$style.link"
        >
          {{ $t('sidebar.privacy') }}
        </link-with-icon>
        <link-with-icon v-if="hasCancelButton" :class="$style.link" @click="$emit('cancel')">
          {{ $t('buttons.cancelAndLeave') }}
        </link-with-icon>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useWindowScroll } from '@vueuse/core';

import {
  ArrowBackIcon,
  CloseIconRounded,
  DriversIcon,
  LinkWithIcon,
  PrimaryButton,
} from '@/components/base';

export default defineComponent({
  emits: ['back', 'cancel'],
  components: {
    ArrowBackIcon,
    CloseIconRounded,
    DriversIcon,
    LinkWithIcon,
    PrimaryButton,
  },
  data: ({ alwaysOpen }) => ({
    showMenu: alwaysOpen,
  }),
  setup() {
    const { y: scrollTop } = useWindowScroll();

    return {
      scrollTop,
    };
  },
  props: {
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
    animateOnScroll: {
      type: Boolean,
      default: false,
    },
    hasBackButton: {
      type: Boolean,
      default: true,
    },
    hasCancelButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    toggleMenuOnChildAction() {
      if (!this.alwaysOpen) {
        this.toggleMenu();
      }
    },
  },
  computed: {
    scroll() {
      return !this.showMenu && !!this.animateOnScroll && this.scrollTop > 0;
    },
  },
  provide() {
    return {
      toggleMenu: this.toggleMenuOnChildAction,
    };
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.mobile-header {
  position: sticky;
  top: 0;
  width: 100%;

  display: none;

  background: vars.$yellow;
  z-index: vars.$z-index-backdrop-above;

  :global(.dark-theme) & {
    background: transparent;
  }

  @include mixins.media(md, max) {
    display: block;
  }

  &.scroll {
    background: transparent;
    pointer-events: none;
  }
}

.header-wrapper {
  position: relative;
  display: grid;
  align-items: center;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'left logo right';
  padding: 1.5em 1.25em 1em;

  border-bottom: 1px solid rgba(21, 23, 26, 0.12);

  transition: transform 0.35s ease-in-out;

  :global(.dark-theme) & {
    border-bottom: none;
  }

  :global(.dark-theme) &.with-back-btn {
    border-bottom: 1px solid hsla(0, 0%, 94.9%, 0.12);
  }

  @include mixins.media(md) {
    padding: 2em 2.5em;
  }

  &.scroll {
    transform: translateY(-100%);
  }
}

.section {
  align-self: center;
}

.section-left {
  @extend .section;
  justify-self: start;
  grid-area: left;

  > svg {
    color: vars.$dark-gray;

    :global(.dark-theme) & {
      color: vars.$light-gray;
    }
  }

  :global(.dark-theme) & {
    padding: 8px;
  }
}

.logo {
  @extend .section;

  justify-self: center;
  grid-area: logo;

  :global(.dark-theme) & {
    display: none;
  }
}

.section-right {
  @extend .section;

  justify-self: end;
  grid-area: right;

  .open-btn {
    min-width: 6em;
    height: 2.5em;
  }

  .close-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 2.7em;
    min-width: initial;
    height: 2.7em;
    padding: 0;

    font-size: 14px;
  }

  :global(.dark-theme) & {
    display: none;
  }
}

.menu-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0.4em 1.25em 1em;

  border-bottom: 1px solid rgba(21, 23, 26, 0.12);
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 24px 0;
}

.links {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .link {
    padding: 0 20px 0 25px;
  }
}
</style>
