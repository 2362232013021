<template>
  <transition
    appear
    :enter-active-class="
      variant === 'a'
        ? $style['slide-enter-active-a']
        : variant === 'b'
          ? $style['slide-enter-active-b']
          : variant === 'c'
            ? $style['slide-enter-active-c']
            : $style['slide-enter-active-d']
    "
    :enter-from-class="
      variant === 'a'
        ? $style['slide-enter-from-a']
        : variant === 'b'
          ? $style['slide-enter-from-b']
          : variant === 'c'
            ? $style['slide-enter-from-c']
            : $style['slide-enter-from-d']
    "
    :enter-to-class="$style['slide-enter-to']"
  >
    <slot />
  </transition>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    variant: {
      type: String as PropType<'a' | 'b' | 'c' | 'd'>,
      default: 'a',
    },
  },
});
</script>

<style lang="scss" module>
.slide-enter-active-a {
  transition:
    transform cubic-bezier(0, 0.97, 0, 0.7) 0.4s,
    opacity ease-out 0.2s;
}

.slide-enter-from-a {
  transform: translateX(-40%);
  opacity: 0;
}

.slide-enter-active-b {
  transition:
    transform cubic-bezier(0, 0.97, 0, 0.7) 0.4s,
    opacity ease-out 0.2s;
}

.slide-enter-from-b {
  transform: translateX(-20%);
  opacity: 0;
}

.slide-enter-active-c {
  transition:
    transform cubic-bezier(0.32, 0.92, 0.31, 0.99) 1s,
    opacity ease-out 0.7s;
}

.slide-enter-from-c {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-enter-active-d {
  transition:
    transform cubic-bezier(0, 0.97, 0, 0.7) 0.4s,
    opacity ease-out 0.2s;
}

.slide-enter-from-d {
  transform: translateX(-60%);
  opacity: 0;
}

.slide-enter-to {
  transform: translateX(0);
  opacity: 1;
}
</style>
