import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_globe_icon = _resolveComponent("globe-icon")!
  const _component_arrow_up_right_icon = _resolveComponent("arrow-up-right-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['locale-selector']),
    ref: "containerRef"
  }, [
    _createElementVNode("button", {
      class: _normalizeClass(_ctx.$style['locale-btn']),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
    }, [
      _createVNode(_component_globe_icon, {
        class: _normalizeClass(_ctx.$style['icon-globe'])
      }, null, 8 /* PROPS */, ["class"]),
      _createTextVNode(),
      _createElementVNode("span", null, _toDisplayString(_ctx.activeLocale), 1 /* TEXT */),
      _createVNode(_component_arrow_up_right_icon, {
        class: _normalizeClass(_ctx.$style['icon-arrow'])
      }, null, 8 /* PROPS */, ["class"])
    ], 2 /* CLASS */),
    (_ctx.dropdownVisible)
      ? (_openBlock(), _createElementBlock("nav", {
          key: 0,
          class: _normalizeClass(_ctx.$style.dropdown)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inactiveLocales, ({ label, code }) => {
            return (_openBlock(), _createElementBlock("a", {
              key: code,
              onClick: ($event: any) => (_ctx.setLocale(code))
            }, _toDisplayString(label), 9 /* TEXT, PROPS */, _hoisted_1))
          }), 128 /* KEYED_FRAGMENT */))
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}