import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["action"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_permission_tag = _resolveComponent("permission-tag")!
  const _component_primary_button = _resolveComponent("primary-button")!
  const _component_content_block = _resolveComponent("content-block")!

  return (_openBlock(), _createBlock(_component_content_block, null, {
    header: _withCtx(() => [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.permissions.description')), 1 /* TEXT */)
    ]),
    content: _withCtx(() => [
      _createElementVNode("h1", {
        class: _normalizeClass(_ctx.$style.title)
      }, _toDisplayString(_ctx.$t('views.permissions.title')), 3 /* TEXT, CLASS */),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.permissions)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.capabilities, ({ name, permissions }) => {
          return (_openBlock(), _createBlock(_component_permission_tag, {
            "permission-tag": "",
            capability: name,
            permissions: permissions,
            key: name
          }, null, 8 /* PROPS */, ["capability", "permissions"]))
        }), 128 /* KEYED_FRAGMENT */))
      ], 2 /* CLASS */),
      _createElementVNode("form", {
        method: "post",
        action: _ctx.action
      }, [
        _createVNode(_component_primary_button, {
          class: _normalizeClass(_ctx.$style.cta),
          type: "submit",
          "data-role": "authorize",
          large: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('buttons.accept')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["class"])
      ], 8 /* PROPS */, _hoisted_1)
    ]),
    _: 1 /* STABLE */
  }))
}