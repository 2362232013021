<template>
  <div :class="$style.footer">
    <div :class="$style.top">
      <drivers-icon :class="$style.logo" />
      <locale-selector v-if="localeSelector" :class="$style['locale-selector']" />
    </div>
    <div :class="$style.bottom">
      <div>
        <p>© 2020-{{ new Date().getFullYear() }} <span>High Mobility GmbH</span></p>
      </div>
      <div>
        <a href="https://www.high-mobility.com/legal/imprint" target="_blank">
          {{ $ttr('footer.imprint') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { DriversIcon, LocaleSelector } from '@/components/base';

export default defineComponent({
  components: {
    DriversIcon,
    LocaleSelector,
  },
  props: {
    localeSelector: {
      type: Boolean,
      default: true,
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/functions' as fn;
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.footer {
  position: relative;
  width: 100%;

  margin-top: auto;

  @include mixins.media(md, max) {
    padding: 2.5em 2.5em 1em;

    border-top: 1px solid rgba(21, 23, 26, 0.12);
  }

  @include mixins.media(sm, max) {
    padding: 1.25em 1.25em 1em;
  }

  @include mixins.media(lg) {
    position: fixed;
    left: auto;
    top: auto;
    right: 2.5em;
    bottom: 1em;
    margin: 0;

    width: auto;
  }

  @media (min-width: fn.get-breakpoint-width(lg)) and (max-width: 1400px) {
    position: relative;
    inset: initial;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 2em;

    :global(.dark-theme) & {
      padding-right: 2.5em;
      margin-bottom: 1em;
    }
  }
}

.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.top {
  @extend .section;

  .logo {
    max-width: 6em;
    height: auto;

    color: inherit;
  }

  .locale-selector button {
    @include mixins.media(xs, max) {
      padding: 16px 8px;
      height: 2em;
    }

    :global(.visuals-background) & {
      background: vars.$yellow;
    }
  }
}

.bottom {
  @extend .section;
  margin-top: 1em;

  a,
  a:hover,
  a:visited {
    text-decoration: none;
  }

  a,
  p,
  span {
    font-size: 12px;
  }

  a,
  span {
    font-weight: 600;
  }
}

@include mixins.media(lg) {
  .top .logo,
  .bottom {
    display: none;
  }
}
</style>
