import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sidebar = _resolveComponent("sidebar")!
  const _component_desktop_header = _resolveComponent("desktop-header")!
  const _component_mobile_header = _resolveComponent("mobile-header")!
  const _component_page_footer = _resolveComponent("page-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_sidebar),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layoutComponent), null, {
      default: _withCtx(() => [
        _createVNode(_component_desktop_header, {
          hasBackButton: _ctx.hasBackButton,
          onBack: _ctx.goBack
        }, null, 8 /* PROPS */, ["hasBackButton", "onBack"]),
        _createVNode(_component_mobile_header, {
          hasBackButton: _ctx.hasBackButton,
          onBack: _ctx.goBack
        }, null, 8 /* PROPS */, ["hasBackButton", "onBack"]),
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.viewComponent))),
        _createVNode(_component_page_footer, { "locale-selector": false })
      ]),
      _: 1 /* STABLE */
    }))
  ], 64 /* STABLE_FRAGMENT */))
}