<template>
  <svg :class="$style.icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM12.0634 13.766C12.5337 14.2363 13.2962 14.2363 13.7665 13.766C14.2367 13.2957 14.2367 12.5332 13.7665 12.0629L11.7162 10.0127L13.7665 7.96249C14.2367 7.49219 14.2367 6.7297 13.7665 6.25941C13.2962 5.78911 12.5337 5.78911 12.0634 6.25941L10.0132 8.30961L7.96296 6.2594C7.49266 5.78911 6.73017 5.78911 6.25987 6.2594C5.78958 6.7297 5.78958 7.49219 6.25987 7.96249L8.31008 10.0127L6.25987 12.0629C5.78958 12.5332 5.78958 13.2957 6.25987 13.766C6.73017 14.2363 7.49266 14.2363 7.96296 13.766L10.0132 11.7158L12.0634 13.766Z"
      fill="currentColor"
    />
  </svg>
</template>

<style lang="scss" module>
@use '~styles/variables' as vars;

.icon {
  width: 20px;
  height: auto;

  color: vars.$dark-gray;
}
</style>
