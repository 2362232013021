import { onMounted, onUnmounted } from 'vue';

export function useBodyClass(token: string) {
  const toggleClass = (add: boolean) => {
    if (add) {
      if (!document.body.classList.contains(token)) {
        document.body.classList.add(token);
      }
    } else {
      document.body.classList.remove(token);
    }
  };

  onMounted(() => toggleClass(true));
  onUnmounted(() => toggleClass(false));
}
