import { I18n } from 'vue-i18n';
import { Plugin } from 'vue';

import { ttr } from '@/locales/utils';

export function createI18nHelper(i18n: I18n): Plugin {
  return (app) => {
    app.config.globalProperties.$ttr = ttr(i18n);
  };
}
