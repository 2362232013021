<template>
  <svg :class="$style.icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.8391 3H4.16086C4.09214 3 4.04472 3.02536 4.01967 3.04856C4.00742 3.05992 4.00247 3.06884 4.00093 3.07221C3.99997 3.07431 3.99998 3.07472 4 3.07535C4 3.07544 4 3.07525 4 3.07535L4 16.9244C3.99998 16.925 3.99997 16.9257 4.00093 16.9278C4.00247 16.9312 4.00742 16.9401 4.01968 16.9514C4.04472 16.9746 4.09214 17 4.16086 17H14C14.5523 17 15 16.5523 15 16V3.07563C15 3.07499 15 3.07431 14.9991 3.07221C14.9975 3.06884 14.9926 3.05992 14.9803 3.04856C14.9553 3.02536 14.9079 3 14.8391 3ZM4.16086 2H14.8391C15.4803 2 16 2.48158 16 3.07563V16C16 17.1046 15.1046 18 14 18H4.16086C3.51973 18 3 17.5184 3 16.9244V3.07563C3 2.48158 3.51973 2 4.16086 2Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 6.5C6 6.22386 6.22386 6 6.5 6H12.5C12.7761 6 13 6.22386 13 6.5C13 6.77614 12.7761 7 12.5 7H6.5C6.22386 7 6 6.77614 6 6.5Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 12.5C6 12.2239 6.22386 12 6.5 12H11.5C11.7761 12 12 12.2239 12 12.5C12 12.7761 11.7761 13 11.5 13H6.5C6.22386 13 6 12.7761 6 12.5Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 9.5C6 9.22386 6.22386 9 6.5 9H10.5C10.7761 9 11 9.22386 11 9.5C11 9.77614 10.7761 10 10.5 10H6.5C6.22386 10 6 9.77614 6 9.5Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<style lang="scss" module>
@use '~styles/variables' as vars;

.icon {
  width: 20px;
  height: auto;

  color: vars.$dark-gray;
}
</style>
