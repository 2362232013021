// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a{margin-bottom:3rem}.b{display:flex;gap:4px;flex-wrap:wrap;margin-bottom:7rem}.c{width:100%}", "",{"version":3,"sources":["webpack://./src/vw-dat/components/views/ReviewPermissions.vue"],"names":[],"mappings":"AACA,GACE,kBAAA,CAGF,GACE,YAAA,CACA,OAAA,CACA,cAAA,CAEA,kBAAA,CAGF,GACE,UAAA","sourcesContent":["\n.title {\n  margin-bottom: 3rem;\n}\n\n.permissions {\n  display: flex;\n  gap: 4px;\n  flex-wrap: wrap;\n\n  margin-bottom: 7rem;\n}\n\n.cta {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "a",
	"permissions": "b",
	"cta": "c"
};
module.exports = ___CSS_LOADER_EXPORT___;
