export interface BackendData {
  errors: string[];
  step: Step;
}

export enum Step {
  Error = 'error',
  EnterVin = 'enter-vin',
  ReviewPermissions = 'review-permissions',
  Success = 'success',
}
