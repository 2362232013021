import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_drivers_icon = _resolveComponent("drivers-icon")!
  const _component_link_with_icon = _resolveComponent("link-with-icon")!
  const _component_privacy_division_icon = _resolveComponent("privacy-division-icon")!
  const _component_slide_fade = _resolveComponent("slide-fade")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.sidebar)
  }, [
    _createVNode(_component_slide_fade, null, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.container)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style['driver-icon'])
          }, [
            _createVNode(_component_drivers_icon)
          ], 2 /* CLASS */),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.content)
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 2 /* CLASS */),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.navigation)
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.$style.version)
            }, "drivers.app version 0.1", 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.links)
            }, [
              _createVNode(_component_link_with_icon, {
                icon: "chat",
                href: "https://www.drivers.app/feedback",
                target: "_blank",
                external: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$ttr('sidebar.feedback')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_link_with_icon, {
                icon: "doc",
                href: "https://www.high-mobility.com/legal/end-customer-car-data-terms",
                target: "_blank",
                external: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$ttr('sidebar.terms')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_link_with_icon, {
                icon: "doc",
                href: "https://www.high-mobility.com/legal/end-customer-car-data-privacy",
                target: "_blank",
                external: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$ttr('sidebar.privacy')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ], 2 /* CLASS */),
            _createElementVNode("div", null, [
              _createElementVNode("a", {
                class: _normalizeClass(_ctx.$style.privacy),
                href: "https://www.high-mobility.com/company/security",
                target: "_blank"
              }, [
                _createVNode(_component_privacy_division_icon)
              ], 2 /* CLASS */)
            ])
          ], 2 /* CLASS */)
        ], 2 /* CLASS */)
      ]),
      _: 3 /* FORWARDED */
    })
  ], 2 /* CLASS */))
}