<template>
  <content-block>
    <template #header>
      <span>{{ $t('views.permissions.description') }}</span>
    </template>

    <template #content>
      <h1 :class="$style.title">{{ $t('views.permissions.title') }}</h1>

      <div :class="$style.permissions">
        <permission-tag
          v-for="{ name, permissions } in capabilities"
          permission-tag
          :capability="name"
          :permissions="permissions"
          :key="name"
        />
      </div>

      <form method="post" :action="action">
        <primary-button :class="$style.cta" type="submit" data-role="authorize" large>
          {{ $t('buttons.accept') }}
        </primary-button>
      </form>
    </template>
  </content-block>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { ContentBlock } from '@/components/page';
import { PermissionTag, PrimaryButton } from '@/components/base';

import { useViewsStore } from '../../store';

export default defineComponent({
  name: 'ReviewPermissions',
  components: {
    ContentBlock,
    PermissionTag,
    PrimaryButton,
  },
  setup() {
    const views = useViewsStore();

    return {
      views,
    };
  },
  computed: {
    action() {
      return `/authorize?vin=${this.views.vin}`;
    },
    capabilities() {
      // Translate capabilities
      return ['charging', 'odometer', 'ignition'].map((name) => ({
        name: this.$ttr(`capabilities.${name}`),
        permissions: [],
      }));
    },
  },
});
</script>

<style lang="scss" module>
.title {
  margin-bottom: 3rem;
}

.permissions {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;

  margin-bottom: 7rem;
}

.cta {
  width: 100%;
}
</style>
