<template>
  <svg :class="$style.icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.7267 3H3.24788C3.09792 3 3 3.11629 3 3.23077V13.0769C3 13.1914 3.09792 13.3077 3.24788 13.3077H13.2782C14.2125 13.3077 15.0568 13.8829 15.3855 14.7618L15.9873 16.12C15.981 15.7932 15.9745 15.4055 15.9745 14.9896V3.23077C15.9745 3.11629 15.8766 3 15.7267 3ZM14.4575 15.1359L15.1994 16.8103C15.52 17.5336 16 17.5 17 17.5C17 17 17.0157 17.0159 17 16.2579C16.9928 15.9094 17 15.4913 17 14.9896V3.23077C17 2.55103 16.4158 2 15.7267 2H3.24788C2.55869 2 2 2.55103 2 3.23077V13.0769C2 13.7567 2.55869 14.3077 3.24788 14.3077H13.2782C13.8101 14.3077 14.2835 14.6402 14.4575 15.1359Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 6.5C6 6.22386 6.22386 6 6.5 6H12.5C12.7761 6 13 6.22386 13 6.5C13 6.77614 12.7761 7 12.5 7H6.5C6.22386 7 6 6.77614 6 6.5Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 9.5C6 9.22386 6.22386 9 6.5 9H12.5C12.7761 9 13 9.22386 13 9.5C13 9.77614 12.7761 10 12.5 10H6.5C6.22386 10 6 9.77614 6 9.5Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<style lang="scss" module>
@use '~styles/variables' as vars;

.icon {
  width: 20px;
  height: auto;

  color: vars.$dark-gray;
}
</style>
