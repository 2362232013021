import { LocaleConfigurations } from './types';

export const locales: LocaleConfigurations = {
  en: {
    code: 'en-GB',
    label: 'English',
  },
  de: {
    code: 'de-DE',
    label: 'Deutsch',
  },
};

export const defaultLocale = locales.en.code;

export const supportedLocalesCodes = Object.values(locales).map(({ code }) => code);
