export const messages = {
  buttons: {
    accept: 'Annehmen',
    cancel: 'Abbrechen',
    continue: 'Weiter',
    menu: 'Menü',
  },
  capabilities: {
    charging: 'Aufladen',
    ignition: 'Zündung',
    odometer: 'Kilometerzähler',
  },
  errors: {
    'code is invalid': 'code ist ungültig',
    'client_id must be a UUID': 'client_id muss eindeutig sein',
    'client_id is invalid': 'client_id ist ungültig',
    'redirect_uri is invalid': 'redirect_uri ist ungültig',
    'validity_start_date is invalid': 'validity_start_date ist ungültig',
    'validity_end_date is invalid': 'validity_end_date ist ungültig',
    'vehicle is ineligible': 'Datenfreigabe wird vom Fahrzeug nicht unterstützt',
    'vin should be 17 character(s)': 'Die FIN muss 17 Stellen haben',
  },
  footer: {
    imprint: 'Imprint',
  },
  sidebar: {
    feedback: 'Feedback geben',
    privacy: 'Datenschutz',
    terms: 'Bedingungen',
  },
  views: {
    enterVin: {
      title: 'FIN eingeben',
      description: `<p>Sofern Sie in einem Elektrofahrzeug der Marke Volkswagen als Hauptnutzer registriert sind, können Sie einen wertvollen Beitrag im Sinne der Nachhaltigkeit leisten. Im Gegensatz zu vielen theoretischen Analysen soll diese wissenschaftliche Studie die Entwicklung der „Batteriegesundheit“ auf Basis tatsächlichen Ladeverhaltens ermitteln. Ihre Unterstützung kann daher einen entscheidenden Unterschied ausmachen.</p><br/>
          <p>Im Kontext einer zukunftsorientierten und nachhaltigen Entwicklung der Elektromobilität, können Sie an dieser Stelle einen eigenen Beitrag leisten. Denn bei gleicher Fahrleistung lassen sich Batterien mit optimiertem Ladeverhalten länger nutzen und somit Ressourcen und die Umwelt schonen. Darüber hinaus versichern wir, dass <span>keine Rückschlüsse auf ihr persönliches Ladeverhalten</span> abgeleitet werdenund die Ladeinformationen <span>anonymisiert</span> in die Analysen der Studie einfließen.</p><br/>
          <p>Damit dies möglich wird, werden die Volkswagen-Ladeinformationen aus dem Fahrzeug an die High-Mobility GmbH weitergeleitet. High Mobility stellt diese gemäß der Anforderungen zur Datenverarbeitung an die Deutsche Automobil Treuhand GmbH zur Verfügung, hier werden die Daten schließlich  anonymisiert und an die Hochschule Esslingen weitergeleitet.</p><br/>
          <p>Sie werden einmalig zu Beginn gebeten, Ihre datenschutzrechtliche Einwilligung zur Datenverarbeitung gegenüber der High-Mobility GmbH, der Deutsche Automobil Treuhand GmbH und Volkswagen zu erteilen. Danch findet die Übertragung der Ladeinformationen ganz bequem und vollautomatisiert statt.</p><br/>
          <p>Sie können die Einwilligung jederzeit in Ihrer myVolkswagen App widerrufen.</p><br/>
          <p>Vielen Dank für Ihre Mitwirkung und Unterstützung auf dem Weg zu einer nachhaltig optimierten Mobilität der Zukunft.</p>`,
      input: 'Eingabe der 17-stelligen VIN',
      tooltip:
        'FIN steht für Vehicle Identification Number (Fahrzeugidentifikationsnummer) und ist für jedes Fahrzeug einzigartig. Sie finden sie in Ihrem Fahrzeughandbuch oder auf dem unteren Teil der Windschutzscheibe.',
      tooltipTrigger: 'Was ist eine FIN?',
    },
    error: {
      title: 'Entschuldigung, irgendetwas ist fehlgeschlagen',
    },
    permissions: {
      title: 'Die Anwendung wird eine Verbindung herstellen:',
      description:
        'Die folgenden Daten werden von der Deutsche Automobil Treuhand GmbH und der Hochschule Esslingen für die wissenschaftliche Studie verarbeitet.',
    },
    success: {
      title: 'Alles gut!',
      description:
        'Die Datenfreigabe von Ihrem Volkswagen wurde aktiviert. Die Deutsche Automobil Treuhand GmbH und die Hochschule Esslingen erhalten während der Fahrt schreibgeschützte Datenaktualisierungen.',
      info: 'Sie können diesen Zugang jederzeit in Ihrem Volkswagen Portal widerrufen.',
    },
  },
};
