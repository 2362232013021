<template>
  <tippy
    placement="top-end"
    :arrow="false"
    :popperOptions="popperOptions"
    :hideOnClick="false"
    :offset="[0, 4]"
  >
    <div :class="[$style.tag, hasTooltip ? $style['with-tooltip'] : '']">
      <span :class="$style.label">{{ capability }}</span>
      <info-icon v-if="hasTooltip" :class="$style.icon" />
    </div>

    <template #content v-if="hasTooltip">
      <div :class="$style.permissions">
        <span v-for="permission in permissions" :key="permission">
          <strong>{{ capability }}: </strong><span>{{ permission }}</span>
        </span>
      </div>
    </template>
  </tippy>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Modifier } from '@popperjs/core';
import { Tippy } from 'vue-tippy';

import InfoIcon from '../atoms/InfoIcon.vue';

export default defineComponent({
  components: {
    InfoIcon,
    Tippy,
  },
  props: {
    capability: {
      type: String,
      required: true,
    },
    permissions: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
  },
  computed: {
    hasTooltip() {
      return this.permissions.length > 0;
    },
    popperOptions() {
      /* istanbul ignore next */
      return {
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              altAxis: true,
              padding: 10,
            },
          },
          {
            name: 'sameWidth',
            enabled: true,
            phase: 'beforeWrite',
            requires: ['computeStyles'],
            fn({ state }) {
              state.styles.popper.minWidth = `${state.rects.reference.width}px`;
            },
            effect({ state }) {
              state.elements.popper.style.minWidth = `${
                (state.elements.reference as HTMLDivElement).offsetWidth
              }px`;
            },
          },
        ] as Modifier<string, Record<string, unknown>>[],
      };
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.tag {
  display: flex;
  align-items: center;
  height: 44px;
  padding-right: 10px;
  padding-left: 10px;

  background: rgba(vars.$dark-gray, 0.9);
  border-radius: 6px;

  transition: 0.2s ease-in opacity;

  &:hover {
    opacity: 0.85;
  }

  @include mixins.media(lg) {
    height: 3.6em;
    padding-right: 1.2em;
    padding-left: 1.2em;
  }

  &.with-tooltip {
    padding-left: 14px;

    @include mixins.media(lg) {
      padding-left: 1.6em;
    }
  }
}

.tag-wrapper {
  position: relative;
}

.label {
  .with-tooltip & {
    margin-right: 2em;
  }

  @include mixins.relative-font-size(15px, 0.9vw);
  line-height: 2;
  font-weight: 400;
  color: vars.$yellow;
}

.icon {
  width: 16px;

  @include mixins.media(lg) {
    width: 20px;
  }
}

.permissions {
  display: flex;
  flex-direction: column;

  font-size: 12px;
  line-height: 20px;
}
</style>
