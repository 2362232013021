import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['input-outer-wrapper'])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass({
        [_ctx.$style['input-inner-wrapper']]: true,
        [_ctx.$style.focused]: _ctx.focused,
        [_ctx.$style.error]: _ctx.hasError,
      })
    }, [
      _createElementVNode("input", _mergeProps(_ctx.$attrs, {
        value: _ctx.modelValue,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
        ref: "inputRef"
      }), null, 16 /* FULL_PROPS */, _hoisted_1),
      (_ctx.hasComponent)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style['right-component'])
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */),
    (_ctx.hasError)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(_ctx.$style['error-text'])
        }, _toDisplayString(_ctx.error), 3 /* TEXT, CLASS */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}