import { I18n } from 'vue-i18n';

import { defaultLocale, locales } from './locales';
import { Locale } from './types';

export const detectLocale = (supportedLocalesCodes: Locale[], fallback = defaultLocale): Locale => {
  let detectedLocales = [];

  if (window.location.search) {
    const params = new URLSearchParams(window.location.search);
    if (params.has('locale')) {
      detectedLocales.push(params.get('locale'));
    }
  }

  if (window.navigator.languages) {
    detectedLocales = [...detectedLocales, ...window.navigator.languages];
  }

  if (window.navigator.language) {
    detectedLocales.push(window.navigator.language);
  }

  let match = null;
  for (const detectedLocale of detectedLocales) {
    match =
      supportedLocalesCodes.find((locale) => locale === detectedLocale) ||
      supportedLocalesCodes.find(
        (locale) => locale.split('-')[0] === detectedLocale?.split('-')[0],
      );

    if (match) break;
  }

  return match || fallback;
};

export const localeCodeToAlpha2 = (code: string | Locale, configuration = locales) => {
  const [result] = Object.entries(configuration).find(([, config]) => config.code === code) || [];
  return result || 'en';
};

export const localeCodeToLabel = (code: string | Locale, configuration = locales) =>
  Object.values(configuration).find((locale) => locale.code === code)?.label;

export const ttr =
  (i18n: I18n) =>
  (key: string, fallback = key) => {
    if (i18n.global.te(key)) {
      return (i18n.global.t as (k: string) => string)(key);
    }

    return fallback;
  };
