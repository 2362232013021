import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_back_icon = _resolveComponent("arrow-back-icon")!
  const _component_drivers_icon = _resolveComponent("drivers-icon")!
  const _component_close_icon_rounded = _resolveComponent("close-icon-rounded")!
  const _component_primary_button = _resolveComponent("primary-button")!
  const _component_link_with_icon = _resolveComponent("link-with-icon")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass({
      [_ctx.$style['mobile-header']]: true,
      [_ctx.$style.scroll]: _ctx.scroll,
    })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass({
        [_ctx.$style['header-wrapper']]: true,
        [_ctx.$style.scroll]: _ctx.scroll,
        [_ctx.$style['with-back-btn']]: _ctx.hasBackButton,
      })
    }, [
      (_ctx.hasBackButton)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: _normalizeClass(_ctx.$style['section-left']),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('back')))
          }, [
            _createVNode(_component_arrow_back_icon)
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_drivers_icon, {
        class: _normalizeClass(_ctx.$style.logo)
      }, null, 8 /* PROPS */, ["class"]),
      (!_ctx.alwaysOpen)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(_ctx.$style['section-right'])
          }, [
            (_ctx.showMenu)
              ? (_openBlock(), _createBlock(_component_primary_button, {
                  key: 0,
                  class: _normalizeClass(_ctx.$style['close-btn']),
                  rounded: "",
                  onClick: _ctx.toggleMenu
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_close_icon_rounded)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["class", "onClick"]))
              : (_openBlock(), _createBlock(_component_primary_button, {
                  key: 1,
                  class: _normalizeClass(_ctx.$style['open-btn']),
                  rounded: "",
                  onClick: _ctx.toggleMenu
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('buttons.menu')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["class", "onClick"]))
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */),
    (_ctx.showMenu)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style['menu-wrapper']),
          "data-role": "menu"
        }, [
          (_ctx.$slots.default)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style['content-wrapper'])
              }, [
                _renderSlot(_ctx.$slots, "default")
              ], 2 /* CLASS */))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.links)
          }, [
            _createVNode(_component_link_with_icon, {
              icon: "doc",
              href: "https://www.high-mobility.com/legal/end-customer-car-data-terms",
              target: "_blank",
              external: "",
              class: _normalizeClass(_ctx.$style.link)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('sidebar.terms')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["class"]),
            _createVNode(_component_link_with_icon, {
              icon: "doc",
              href: "https://www.high-mobility.com/legal/end-customer-car-data-privacy",
              target: "_blank",
              external: "",
              class: _normalizeClass(_ctx.$style.link)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('sidebar.privacy')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["class"]),
            (_ctx.hasCancelButton)
              ? (_openBlock(), _createBlock(_component_link_with_icon, {
                  key: 0,
                  class: _normalizeClass(_ctx.$style.link),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('buttons.cancelAndLeave')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["class"]))
              : _createCommentVNode("v-if", true)
          ], 2 /* CLASS */)
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}