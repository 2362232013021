<template>
  <content-block>
    <template #header>
      <div :class="$style.header" v-html="$t('views.enterVin.description')" />
    </template>

    <template #content>
      <h1 :class="$style.title">{{ $t('views.enterVin.title') }}</h1>

      <custom-form :error-messages="errorMessages" :set-errors="setErrors" @submit="completeStep">
        <div :class="$style.form">
          <text-input
            minlength="17"
            maxlength="17"
            name="vin"
            type="text"
            required
            :error="vinError"
            :placeholder="$t('views.enterVin.input')"
            v-model="vin"
          >
            <tippy placement="top" :arrow="false" :hideOnClick="false">
              <span :class="$style.tooltip">{{ $t('views.enterVin.tooltipTrigger') }}</span>
              <template #content>
                {{ $t('views.enterVin.tooltip') }}
              </template>
            </tippy>
          </text-input>
        </div>

        <primary-button data-role="submit" :class="$style.cta" type="submit" large>
          {{ $t('buttons.continue') }}
        </primary-button>
      </custom-form>
    </template>
  </content-block>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { Tippy } from 'vue-tippy';

import { ContentBlock } from '@/components/page';
import {
  CustomForm,
  FormErrorType,
  PrimaryButton,
  TextInput,
  useFormErrors,
} from '@/components/base';

import { Step } from '../../types';
import { useViewsStore } from '../../store';

export const InvalidVINError = 'vin should be 17 character(s)';

export default defineComponent({
  name: 'EnterVIN',
  components: {
    ContentBlock,
    CustomForm,
    PrimaryButton,
    TextInput,
    Tippy,
  },
  setup() {
    const { errors, errorMessages, setErrors, useError } = useFormErrors({
      vin: {
        [FormErrorType.TooShort]: InvalidVINError,
        [FormErrorType.ValueMissing]: InvalidVINError,
      },
    });

    const views = useViewsStore();
    const vin = ref<string>(views.vin);

    return {
      errors,
      errorMessages,
      setErrors,
      useError,
      views,
      vin,
    };
  },
  computed: {
    vinError() {
      return this.useError('vin', (v) => this.$t(`errors.${v}`));
    },
  },
  methods: {
    completeStep() {
      this.views.setVin(this.vin);
      this.views.setStep(Step.ReviewPermissions);
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/variables' as vars;

.header span {
  text-decoration: underline;
  font-weight: 500;
}

.title {
  margin-bottom: 3rem;
}

.form {
  margin-bottom: 3.5rem;
}

.tooltip {
  padding: 0 1em;

  font-size: 12px;
  line-height: 20px;
  color: vars.$dark-gray;
  text-decoration: underline;
  cursor: pointer;
}

.cta {
  width: 100%;
}
</style>
