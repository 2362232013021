<template>
  <sidebar />

  <component :is="layoutComponent">
    <desktop-header :hasBackButton="hasBackButton" @back="goBack" />
    <mobile-header :hasBackButton="hasBackButton" @back="goBack"> </mobile-header>

    <component :is="viewComponent" />
    <page-footer :locale-selector="false" />
  </component>
</template>

<script lang="ts">
import { Component, defineComponent } from 'vue';

import {
  DesktopHeader,
  MainLayout,
  MobileHeader,
  PageFooter,
  Sidebar,
  SimpleLayout,
} from '@/components/page';
import { useViewHeight } from '@/components/composables';

import { Step } from '../types';
import { useViewsStore } from '../store';

import { EnterVIN, ErrorView, ReviewPermissionsView, SuccessView } from './views';

const components: Record<Step, Component> = {
  [Step.EnterVin]: EnterVIN,
  [Step.Error]: ErrorView,
  [Step.ReviewPermissions]: ReviewPermissionsView,
  [Step.Success]: SuccessView,
};

const simpleLayoutViews = [Step.Error];
const viewsWithoutBackButton = [Step.Error, Step.EnterVin, Step.Success];

export default defineComponent({
  components: {
    DesktopHeader,
    MainLayout,
    MobileHeader,
    PageFooter,
    Sidebar,
  },
  setup: () => {
    const views = useViewsStore();

    useViewHeight();

    return {
      views,
    };
  },
  computed: {
    activeStep() {
      return this.views.activeStep;
    },
    hasBackButton() {
      return !viewsWithoutBackButton.includes(this.activeStep);
    },
    layoutComponent() {
      return simpleLayoutViews.includes(this.activeStep) ? SimpleLayout : MainLayout;
    },
    viewComponent() {
      return components[this.activeStep];
    },
  },
  methods: {
    goBack() {
      this.views.restorePreviousStep();
    },
  },
});
</script>
