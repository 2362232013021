<template>
  <div :class="$style['input-outer-wrapper']">
    <div
      :class="{
        [$style['input-inner-wrapper']]: true,
        [$style.focused]: focused,
        [$style.error]: hasError,
      }"
    >
      <input
        v-bind="$attrs"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        ref="inputRef"
      />
      <div v-if="hasComponent" :class="$style['right-component']">
        <slot />
      </div>
    </div>
    <span v-if="hasError" :class="$style['error-text']">{{ error }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { useFocus } from '@vueuse/core';

export default defineComponent({
  emits: ['update:modelValue'],
  inheritAttrs: false,
  setup(_, { slots }) {
    const hasComponent = ref(false);
    if (slots.default && slots.default().length) {
      hasComponent.value = true;
    }

    const inputRef = ref(null);
    const { focused } = useFocus(inputRef);

    return {
      focused,
      hasComponent,
      inputRef,
    };
  },
  props: {
    error: {
      type: String as PropType<string | undefined>,
      required: false,
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasError() {
      return !!this.error;
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.input-outer-wrapper {
  display: flex;
  flex-direction: column;
}

.input-inner-wrapper {
  display: flex;
  flex: 1;
  gap: 10px;
  padding: 7px 1em;

  border: 1px solid rgba(31, 41, 55, 0.12);
  background-color: rgba(31, 41, 55, 0.04);

  input {
    display: flex;
    flex: 1 1;
    width: 100%;
    min-width: 0;
    height: calc(4em - 2 * 8px);

    font-size: 18px;

    @include mixins.relative-font-size(18px, 1vw);
    line-height: 2em;
    font-weight: 400;

    color: #1f2937;

    background: transparent;

    &::placeholder {
      color: vars.$yellow;
      opacity: 1;
    }
  }

  :global(.dark-theme) & {
    background: transparent;
    border-color: rgb(242, 242, 242);

    input {
      color: vars.$yellow;

      &::placeholder {
        color: #999;
      }

      @include mixins.media(xs, max) {
        height: calc(3.5em - 2 * 8px);
      }

      @include mixins.media(sm, max) {
        font-size: 1.2rem;
      }
    }
  }

  input:autofill,
  input:-webkit-autofill-strong-password,
  input:-webkit-autofill-strong-password-viewable,
  input:-webkit-autofill-and-obscured {
    transition: background-color 5000s ease-in-out 0s;
    color: #1f2937;
    -webkit-text-fill-color: #1f2937;

    :global(.dark-theme) & {
      color: vars.$yellow;
      -webkit-text-fill-color: vars.$yellow;
    }
  }
}

.input-inner-wrapper:hover:not(.focused) {
  background-color: rgba(31, 41, 55, 0.12);

  :global(.dark-theme) & {
    background-color: rgba(31, 41, 55, 0.25);
  }
}

.input-inner-wrapper.focused {
  border-color: vars.$dark-gray;
  background-color: rgba(31, 41, 55, 0.14);

  :global(.dark-theme) & {
    border-color: vars.$yellow;
  }
}

.right-component {
  display: flex;
  align-items: center;
}

.input-inner-wrapper.error {
  border-color: rgb(255, 153, 0);

  input {
    color: rgb(255, 153, 0);
  }
}

.error-text {
  display: flex;
  padding: 8px 0;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: rgb(255, 153, 0);
}
</style>
